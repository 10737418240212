import React from 'react';
import './ChromeExtensionOutlink.scss';
import chromeLogo from '@browser-logos/chrome/chrome_64x64.png'; // Adjust the path as necessary

const ChromeExtensionOutlink = () => {
    return (
        <div>
            {/* replace this link with actual link before launch */}
            {/* could we store somthing in local storage when they click this button so that 
            if they install the extension right here we log them in right away? */}
            <a href="https://chromewebstore.google.com/detail/speechify-text-to-speech/ljflmlehinmoeknoonhibbjpldiijjmm" target="_blank" rel="noopener noreferrer">
                <div className='chrome-extension-custom-outlink'>
                    <img src={chromeLogo} alt="Chrome Logo" className="chrome-logo" />
                    <span className='text-styling'>Download for Google Chrome</span>
                </div>
            </a>
        </div>
    );
};

export default ChromeExtensionOutlink;
import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { ReactComponent as GroupsIcon } from "../../../resources/participantsIcon.svg";
import ProfilePip from "../../ProfilePip/ProfilePip";

import "./LinePreviewer.scss";

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength).trim() + "...";
  } else {
    return text;
  }
}

function LinePreviewer({
  title,
  coverPic,
  bio,
  owner,
  participants,
  site,
  onChange,
}) {
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentCoverPic, setCurrentCoverPic] = useState("");
  const [currentBio, setCurrentBio] = useState("");
  const [currentOwner, setCurrentOwner] = useState("");
  const [currentParticipants, setCurrentParticipants] = useState("");
  const [currentSite, setCurrentSite] = useState("");

  const bioRef = useRef(null);
  
  useEffect(() => {
    setCurrentTitle(title);
    setCurrentCoverPic(coverPic);
    setCurrentBio(bio);
    setCurrentOwner(owner);
    setCurrentParticipants(participants);
    setCurrentSite(site);
  }, [title, coverPic, bio, owner, participants, site]);

  return (
    <div className="line-previewer sad">
      <span className={`heading-line`}>
        {<h2 className="preview-title">{currentTitle}</h2>}
        <span className={`line-participant-count`}>
          <GroupsIcon className="participants-icon" />
          {currentParticipants}
        </span>
      </span>
      <img
        className="line-thumb-nail"
        src={currentCoverPic}
        alt={`${currentTitle} cover image`}
      />
      <p className="preview-bio" ref={bioRef}>{currentBio}</p>
      <ProfilePip userId={currentOwner} />
    </div>
  );
}

LinePreviewer.propTypes = {
  title: PropTypes.string.isRequired,
  coverPic: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  participants: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  site: PropTypes.string,
  onChange: PropTypes.func
};

export default LinePreviewer;
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h4>About Us</h4>
                    <ul>
                        <li><Link to="/about">Our Mission</Link></li>
                        <li><Link to="/about">Team</Link></li>
                        {/* <li><Link to="/about">Press</Link></li> */}
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Get Involved</h4>
                    <ul>
                        <li><Link to="/create-line">Start a Campaign</Link></li>
                        <li><Link to="/search">Join a Campaign</Link></li>
                        {/* <li><Link to="/about">Volunteer</Link></li> */}
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Support</h4>
                    <ul>
                        <li><Link to="/about">FAQ</Link></li>
                        <li><Link to="/about">Contact Us</Link></li>
                        <li><Link to="https://ko-fi.com/pickett">Donate</Link></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Legal</h4>
                    <ul>
                        <li><Link to="/about">Privacy Policy</Link></li>
                        <li><Link to="/about">Terms of Service</Link></li>
                        <li><Link to="/about">Cookie Policy</Link></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2025 Pickett Org. All rights reserved.</p>
                <div className="social-links">
                    <a href="https://reddit.com/r/pickett" aria-label="Reddit"><i className="fab fa-reddit"></i></a>
                    <a href="https://twitter.com" aria-label="Twitter"><i className="fab fa-twitter"></i></a>
                    <a href="https://facebook.com" aria-label="Facebook"><i className="fab fa-facebook"></i></a>
                    <a href="https://instagram.com" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
/* global chrome */

const extensionId = "kakhjmckmkcponjjllcoledhmglccone";

export const checkExtensionInstalled = () => {
    return new Promise((resolve) => {
        try {
            chrome.runtime.sendMessage(
                extensionId,
                { message: "ping" },
                (response) => {
                    if (chrome.runtime.lastError) {
                        console.error("Error:", chrome.runtime.lastError.message);
                        resolve(false);
                    } else if (response && response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }
            );
        } catch (error) {
            console.error("Exception:", error);
            resolve(false);
        }
    });
};

export const sendTokenToExtension = (token) => {
    try {
        chrome.runtime.sendMessage(
            extensionId,
            { message: "token", token },
            (response) => {
                if (chrome.runtime.lastError) {
                    console.error("Error:", chrome.runtime.lastError.message);
                } else if (response && response.success) {
                    console.log("Token sent to extension");
                } else {
                    console.error("Unexpected response:", response);
                }
            }
        );
    } catch (error) {
        console.error("Exception:", error);
    }
};

export const sendMessagesToExtension = (message) => {
    try {
        chrome.runtime.sendMessage(
            extensionId,
            { message: message },
            (response) => {
                if (chrome.runtime.lastError) {
                    console.error("Error:", chrome.runtime.lastError.message);
                } else if (response && response.success) {
                    console.log("Message sent to extension");
                } else {
                    console.error("Unexpected response:", response);
                }
            }
        );
    } catch (error) {
        console.error("Exception:", error);
    }
}


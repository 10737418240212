import React, { useState, useEffect } from "react";
import "./CreateLine.scss";

// general components
import PercentHeader from "../../components/PercentCompletionHeader/PercentCompletionHeader";
import FlowComponent from "../../components/FlowComponent/FlowComponent";

// steps
import LinePreview from "../../components/LinePreview/LinePreview";
import TitleDesc from "../../components/LineCreationFlowElements/TitleDesc/TitleDesc";
// import ColorOptions from "../../components/LineCreationFlowElements/ColorOptions/ColorOptions";
import LineBannerUpload from "../../components/LineCreationFlowElements/LineBannerUpload/LineBannerUpload";
import LineTagSelection from "../../components/LineCreationFlowElements/LineTagSelection/LineTagSelection";
import PickettList from "../../components/LineCreationFlowElements/PickettList/PickettList";

import axios from 'axios';

const CreateLine = () => {
  // add data context? need to have data sent out? maybe have an update on click?
  // should info be stored in cookies when the user is mid line creation or should it just perma update cloud, if so how often?
  
  const [previewInfo, setPreviewInfo] = useState({
    title: "",
    description: "",
    background: "#FFFFFF",
    text: "#000000",
    accent: "#EB675F",
    primary: "#EDEDED",
    lineBanner: null,
    tags: [],
    urls: [{
      mainUrl: '',
      description: '',
      altItems: []
    }]
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([false, false, false, false]);
  const exampleTags = [
    "Social Justice",
    "Human Rights",
    "Animal Rights",
    "Environment",
    "Workers Rights",
    "CorporateMalpractice",
    "Other",
  ];

  const newLineSubmit = async () => {
    const data ={
        name: previewInfo.title,
        bio: previewInfo.description,
        tags: previewInfo.tags,
        urls: previewInfo.urls,
    }
    try {
        const response = await axios.post('/api/create-line', data, {headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`}});
        if(response.success){
            console.log(response);
        }
    } catch (err){
        console.log(err);
    }
  };
  
  useEffect(() => {
    console.log(previewInfo);
  }, [previewInfo]);

  useEffect(() => {
    switch (currentStep) {
      case 0:
        if (!completedSteps[0]) {
          setPercentCompleted(0);
          setCompletedSteps([true, false, false, false]);
        }
        break;
      case 1:
        if (!completedSteps[1]) {
          setPercentCompleted(22);
          setCompletedSteps([true, true, false, false]);
        }
        break;
      case 2:
        if (!completedSteps[2]) {
          setPercentCompleted(57);
          setCompletedSteps([true, true, true, false]);
        }
        break;
      case 3:
        if (!completedSteps[3]) {
          setPercentCompleted(73);
          setCompletedSteps([true, true, true, true]);
        }
        break;
      default:
        alert("Invalid step");
        break;
    }
  }, [currentStep]);

  return (
    <div className="create-line">
      <PercentHeader
        mainText="Draw your"
        accentText="line."
        currentPercent={percentCompleted}
      />
      <div className="content">
        <LinePreview previewInfo={previewInfo} />
        <FlowComponent setCurrentStep={setCurrentStep} finalButtonText={"publish"} handleSubmit={newLineSubmit}>
          <TitleDesc
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
              // You might want to add additional logic here
              // to determine if the next button should be enabled
            setNext={(isValid) => {}}
          />
          {/* ColorOptions Currently Combined with BannerUpload since only 1 is used atm */}
          {/* <ColorOptions
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
              // Color selection is always valid, so we can
              // always enable the next button here
            setNext={() => {}}
          /> */}
          <LineBannerUpload
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
              // This will be called when a file is selected or removed
            setNext={(isValid) => {}}
          />
          <LineTagSelection
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
            tagOptions={exampleTags}
            setNext={(isValid) => {}}
          />
          <PickettList
            previewInfo={previewInfo}
            setPreviewInfo={setPreviewInfo}
            setNext={(isValid) => {}}
          />
        </FlowComponent>
      </div>
    </div>
  );
};

export default CreateLine;
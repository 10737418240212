import React, { useState, useEffect, useRef } from 'react';
import './FlowComponent.scss';


const FlowComponent = ({ children, setCurrentStep, finalButtonText, handleSubmit }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0);
    const [height, setHeight] = useState(0);
    const [opacity, setOpacity] = useState(1);
    const contentRef = useRef(null);
    const [nextActive, setNext] = useState(false);
    const [onFirstStep, setOnFirstStep] = useState(true);
    const [onLastStep, setOnLastStep] = useState(false);


    // need to add a custom end state for last step with dif redirects and perhaps like visuals for publishing lines vs signing up
    useEffect(() => {
        if (contentRef.current) {
            // Set initial height
            setHeight(contentRef.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        setOpacity(0); // Start fade out
        const timeoutLength = 500;
        const disappearTimeout = setTimeout(() => {
            setCurrentDisplayIndex(currentIndex);
            setTimeout(() => {
                if (contentRef.current) {
                    setTimeout(() => {
                        
                    setHeight(contentRef.current.offsetHeight);
                    }, 10);
                }
            }, 0); //arbitrary small, flushes the state update
        }, timeoutLength/2); 

        const fadeTimeout = setTimeout(() => {
            setOpacity(1); //start fade in after fade out
        }, timeoutLength); 
        return () => clearTimeout(fadeTimeout);
    }, [currentIndex]);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length);
        setNext(false);
    };

    const prevSlide = () => {
        if(currentIndex != 0) {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + children.length) % children.length);
        setNext(false);
        }
    }

    const completeLine = () => {
        handleSubmit();
        // add logic to actually submit the line
        // should i add a shaing page/invite people after line is created?
    };

    useEffect(() => {

        // current bool to check if on first or last case, not a good solution but quick for now
        if(currentIndex === 0) {
            setOnLastStep(true);
        } else { 
            setOnFirstStep(false);
        }
        
        if (currentIndex === children.length - 1) {
            setOnLastStep(true);
        } else {
            setOnLastStep(false);
        }


        setCurrentStep(currentIndex);
    }, [currentIndex]);

    return (
        <div className="slider-container" style={{ height }}>
            <div
                className="slider-content"
                ref={contentRef}
                style={{ opacity, transition: 'opacity 0.2s ease-in-out' }}
            >

                {React.cloneElement(children[currentDisplayIndex], { setNext })}
            </div>
            <div className="button-container">
                {/* should this be a greyed out animation instead? */}
                {!onFirstStep &&
                    <button className="back" onClick={prevSlide}>back</button>
                }
                {onLastStep &&
                    <button className="next" onClick={() => completeLine()}>{finalButtonText}</button>
                }
                {!onLastStep &&
                    <button disabled={!nextActive} className={`next ${nextActive && "active"}`} onClick={nextSlide}>next</button>
                }
                </div>
        </div>
    );
};

export default FlowComponent;

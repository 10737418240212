import React, { useState, useRef, useEffect } from "react";
import "./Switch.scss";

const Switch = ({ 
  options, 
  onChange, 
  hPadding=12, 
  vPadding=5, 
  bRadius=13, 
  fSize=16, 
  mFSize=13, 
  toggle, 
  setToggle,
  initialSelected = 0 
}) => {
  const [selected, setSelected] = useState(initialSelected);
  const containerRef = useRef(null);
  const optionRefs = useRef([]);

  useEffect(() => {
    setTimeout(() => {
        updateHighlight(selected);
    }, 50);
  }, [selected, options]);

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  const updateHighlight = (index) => {
    if (containerRef.current && optionRefs.current[index]) {
      const option = optionRefs.current[index];
      const container = containerRef.current;
      const highlight = container.querySelector('.highlighted');
      
      highlight.style.left = `${option.offsetLeft}px`;
      highlight.style.width = `${option.offsetWidth}px`;
    }
  };

  const handleClick = (index) => {
    setSelected(index);
    onChange(index);
  };

  const style={
    "--horizontal-padding": `${hPadding}px`,
    "--vertical-padding": `${vPadding}px`,
    "--switch-outer-bradius": `${bRadius}px`,
    "--switch-font-size": `${fSize}px`,
  }

  return (
    <div className="switch" ref={containerRef} style={style}>
      <div className="highlighted"></div>
      {options.map((option, index) => (
        <div
          className={`option ${selected === index ? "selected" : ""}`}
          key={index}
          onClick={() => handleClick(index)}
          ref={(el) => (optionRefs.current[index] = el)}
        >   
          <p>{option}</p>
        </div>
      ))}
    </div>
  );
};

export default Switch;
import React from 'react';
import './PercentCompletionHeader.scss';
import Logo from '../Logo/Logo';

const PercentCompletionHeader = ({ mainText, accentText, currentPercent = 0 }) => {
    return (
        <div className="percent-completion-header">
            <div className="create-header">
                <Logo size={40} />
                <h1>
                    {mainText} <b>{accentText}</b>
                    <div className="progress-line-container">
                        <div 
                            className="progress-line" 
                            style={{ width: `${currentPercent}%` }}
                        ></div>
                        <span className="percent-display">{currentPercent}% done.</span>
                    </div>
                </h1>
            </div>
        </div>
    );
};

export default PercentCompletionHeader;
import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import "./Search.scss";
import { useData } from "../../contexts/DataContext";
import { useFetch } from "../../hooks/useFetch.js";

import NavBar from "../../components/NavBar/NavBar";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import Label from "../../components/Label/Label";
import SearchFilterSelector from "../../components/SearchFilterSelector/SearchFilterSelector";
import LinePreview from "../../components/ListedLinePreview/ListedLinePreview";

import tempPFPLineOwner from "../../resources/highResPFPTest.jpg";
import exampleLineCover from "../../resources/testLineCover.jpg";
import { Icon } from "@iconify/react/dist/iconify.js";

// Pagination Component with fewer visible pages
function Pagination({ currentPage, totalPages, onPageChange, onPrevious, onNext }) {
    const maxVisible = 5; // maximum number of numeric page links to show
  
    const getPageNumbers = () => {
      const pages = [];
      if (totalPages <= maxVisible) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
        return pages;
      }
      
      pages.push(1);
      
      const middleCount = maxVisible - 2; // excluding first and last pages
      
      let start = currentPage - Math.floor(middleCount / 2);
      let end = currentPage + Math.floor(middleCount / 2);
      
      if (start < 2) {
        start = 2;
        end = start + middleCount - 1;
      }
      
      if (end > totalPages - 1) {
        end = totalPages - 1;
        start = end - middleCount + 1;
      }
      
      if (start > 2) {
        pages.push("...");
      }
      
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      
      if (end < totalPages - 1) {
        pages.push("...");
      }
      
      pages.push(totalPages);
      
      return pages;
    };
  
    const pageNumbers = getPageNumbers();
  
    return (
      <div className="pagination">
        <button onClick={onPrevious} disabled={currentPage <= 1}>
            <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
        </button>
        <div className="pages">
            {pageNumbers.map((pageNumber, index) => {
            if (pageNumber === "...") {
                return (
                <span key={index} className="pagination-ellipsis">
                    {pageNumber}
                </span>
                );
            } else {
                return (
                <button
                    key={index}
                    onClick={() => onPageChange(pageNumber)}
                    className={pageNumber === currentPage ? "active" : ""}
                >
                    {pageNumber}
                </button>
                );
            }
            })}
        </div>
        <button onClick={onNext} disabled={currentPage >= totalPages}>
            <Icon icon="material-symbols:arrow-forward-ios-rounded"/>
        </button>
      </div>
    );
  }

function Search() {
  const { fetchData } = useData();

  // useSearchParams to read and update URL query params
  const [searchParams, setSearchParams] = useSearchParams();

  // DO NOT TOUCH PLEASE, will crash your tab
  const participantVals = useMemo(() => ["more", "less"], []);
  const tags = useMemo(() => [], []);

  const [searchFilters, setSearchFilters] = useState(() => {
    const initialSearchText = searchParams.get("name") || "";
    const initialType = searchParams.get("type") || "";
    const initialScale = searchParams.get("scale") || "";
    const participantsFromUrl = searchParams.get("participants");
    let initialParticipantsObj = { compare: "more", num: 0 };
    if (participantsFromUrl) {
      try {
        initialParticipantsObj = JSON.parse(participantsFromUrl);
      } catch (e) {
        console.error("Error parsing participants from URL:", e);
      }
    }
    const initialTags = searchParams.get("tags")
      ? JSON.parse(searchParams.get("tags"))
      : [];
    return {
      searchText: initialSearchText,
      type: initialType,
      scale: initialScale,
      participants: initialParticipantsObj.compare === "more" ? 0 : 1,
      startDate: searchParams.get("startDate") || "",
      endDate: searchParams.get("endDate") || "",
      selectedTags: initialTags,
      participantThreshold: initialParticipantsObj.num,
    };
  });

  const [page, setPage] = useState(() => parseInt(searchParams.get("page")) || 1);
  const [limit] = useState(() => parseInt(searchParams.get("limit")) || 10);

  //other static parameter
  const sort = "popularity";

  //update URL parameters when search filters or pagination change
  useEffect(() => {
    const params = {
      name: searchFilters.searchText,
      type: searchFilters.type,
      scale: searchFilters.scale,
      page: page,
      limit: limit,
      sort: sort,
      participants: JSON.stringify({
        compare: participantVals[searchFilters.participants],
        num: searchFilters.participantThreshold,
      }),
      tags: JSON.stringify(tags),
    };
    setSearchParams(params);
  }, [searchFilters, page, limit, sort, participantVals, tags, setSearchParams]);

  const url = `/api/search?name=${encodeURIComponent(
    searchFilters.searchText
  )}&type=${searchFilters.type}&page=${page}&limit=${limit}&sort=${encodeURIComponent(
    sort
  )}&participants=${encodeURIComponent(
    JSON.stringify({
      compare: participantVals[searchFilters.participants],
      num: searchFilters.participantThreshold,
    })
  )}&tags=${encodeURIComponent(JSON.stringify(tags))}`;

  const searchResults = useFetch(url);
  const [picketts, setPicketts] = useState([]);

  useEffect(() => {
    if (
      searchResults.loading ||
      searchResults.error ||
      !searchResults.data?.data
    )
      return;

    const linesWithSelection = searchResults.data.data.map((line) => ({
      id: line._id,
      name: line.name,
      title: line.name,
      coverPic: line.pfp,
      bio: line.bio,
      organizer: line.organizer?.username || "Unknown",
      organizerPFP: line.organizer?.pfp || "",
      participants: line.followercnt || 0,
      selected: false,
      site: "", 
    }));
    setPicketts(linesWithSelection);
  }, [searchResults.data, searchResults.loading, searchResults.error]);

  useEffect(() => {
    setPage(1);
  }, [searchFilters]);

  const goToPreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const goToNextPage = () => {
    if (searchResults.data && page < searchResults.data.totalPages) {
      setPage(page + 1);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="search page">
      {/* <ScrollingBanner items={picketts.map((pickett) => pickett.name)} /> */}
      <div className="search-content">
        <div className="search-filter">
          <SearchFilterSelector
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
          />
        </div>
        <div className="search-results">
          <div className="search-header">
            {!searchResults.loading &&
              searchResults.data &&
              searchResults.data.total &&
              searchFilters.searchText !== "" && (
                <h3>
                  {searchResults.data.total}{" "}
                  {searchResults.data.total === 1 ? "result" : "results"} for "
                  {searchFilters.searchText}"
                </h3>
              )}
          </div>
          {picketts.map((pickett, index) => (
            <React.Fragment key={index}>
              <LinePreview
                title={pickett.name}
                bio={pickett.bio}
                participantCount={pickett.participants}
                imageSource={pickett.coverPic}
                line={pickett}
              />
              <div
                className="searched-line-spacer-temp"
                style={{ marginBottom: "10px" }}
              ></div>
            </React.Fragment>
          ))}

          {/* Improved Pagination Controls */}
          {searchResults.data && (
            <Pagination
              currentPage={page}
              totalPages={searchResults.data.totalPages || 1}
              onPageChange={handlePageChange}
              onPrevious={goToPreviousPage}
              onNext={goToNextPage}
            />
          )}
        </div>
      </div>
      <div className="pinned-new-line-wrapper">+</div>
    </div>
  );
}

export default Search;
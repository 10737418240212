import React, { useState, useEffect } from 'react';
import './LineBannerUpload.scss';

import FileUpload from '../../FileUpload/FileUpload'; 
import Label from '../../Label/Label'; 

// imports for Color Options
import CustomPallete from '../ColorOptions/CustomPallete/CustomPallete';

const LineBannerUpload = ({ previewInfo, setPreviewInfo, setNext }) => {
    const [hasValidImage, setHasValidImage] = useState(false);

    const colorOptions = [
        {
            name: "accent",
            type: "colors",
            colors: [
                "#EB675F",
                "#EBB35F",
                "#EBDD5F",
                "#8CEB5F",
                "#5F8FEB",
                "#625FEB",
                "#BE5FEB",
                "#EB5FCC",
                "#EB5F5F",
            ]
        }
    ];

    const handleFileChange = (file) => {
        if (file) {
            if(file.type.match("image.*")){
                setHasValidImage(true);
                setNext(true);
                // Update previewInfo if needed
                setPreviewInfo(prevInfo => ({ ...prevInfo, lineBanner: file }));
            } else {
                // replace with actual fail case, might need a try catch instead
                alert("fail case");
            }
        } else {
            setHasValidImage(false);
            setNext(false);
            // Remove lineBanner from previewInfo if needed
            setPreviewInfo(prevInfo => {
                const { lineBanner, ...rest } = prevInfo;
                return rest;
            });
        }
    };

    // onload - check if we already have a line banner
    useEffect(() => {
        if (previewInfo?.lineBanner) {
            setHasValidImage(true);
            setNext(true);
        } else {
            setHasValidImage(false);
            // Don't set next to false if there's no banner but we have a color selected
            setNext(!!previewInfo?.accent);
        }
    }, [previewInfo]);

    const handleColorChange = (color, type) => {
        setPreviewInfo(prev => ({...prev, [type]: color}));
        // Enable next button when a color is selected, even without an image
        setNext(true);
    };

    return (
        <div className="line-banner-upload">
            <Label label="Accent Color" className='Accent-Selector-Label'>
                <div className="color-options">
                    <div className="custom-palettes">
                        {colorOptions.map((option, index) => (
                            <div 
                                key={`color-option-${index}`}
                                className="color-option"
                                style={{ animationDelay: `${index * 0.2}s` }}
                            >
                                <CustomPallete 
                                    options={option} 
                                    onChange={handleColorChange}
                                    selectedColor={previewInfo?.[option.name]}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </Label>
            <Label label="Line Banner">
                <FileUpload 
                    classroomName="line" 
                    onFileChange={handleFileChange}
                    currentFile={previewInfo?.lineBanner}
                />
            </Label>
        </div>
    );
};

export default LineBannerUpload;
import React, { useState, useEffect } from 'react';
import PreviewImage from '../../resources/LinePreview.svg';
import './LinePreview.scss';

const LinePreview = ({ previewInfo }) => {
    const { title, description, background, text, accent, primary, lineBanner} = previewInfo;
    const [animation, setAnimation] = useState(false);
    // const [hasPhoto, setHasPhoto] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setAnimation(true);
        }, 100);
    }, []);

    // this might be excessive
    // useEffect(() => {
    //     if(lineBanner === null) {
    //         setHasPhoto(false);
    //     } else {
    //         setHasPhoto(true);
    //     }
    // }, [lineBanner])

    return (
        <div className={`line-preview ${animation && "animation"}`} style={{ background }}>
            {/* <img src={PreviewImage} alt="Line Preview" /> */}
            <svg width="499" height="325" viewBox="0 0 499 325" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_699_658)">
                    <rect x="38.5518" y="8.46484" width="244.648" height="18.9807" rx="5.33515" transform="rotate(0.0410385 38.5518 8.46484)" fill={primary} />
                    <rect x="39.4404" y="9.34766" width="67.2653" height="17.2582" rx="5.33515" transform="rotate(0.0410385 39.4404 9.34766)" fill={accent} />
                    <g clip-path="url(#clip1_699_658)">
                        <rect x="16.9404" y="10.3203" width="14.359" height="14.359" rx="3.58974" fill={accent} />
                        <line x1="17.0897" y1="10.8869" x2="31.6293" y2="25.4264" stroke="white" stroke-width="1.4359" />
                    </g>
                    <rect x="498.959" y="212.316" width="5.51947" height="5.51947" rx="1.47186" fill={primary} />
                    <rect width="585.22" height="13.4739" transform="translate(0 36)" fill="#35363B" />
                    <rect x="13.3379" y="39.1133" width="71.2871" height="7.24954" rx="3.62477" fill="white" />
                    <rect x="97.9629" y="39.1133" width="48.3303" height="7.24954" rx="3.62477" fill="white" />
                    <rect x="159.631" y="39.1133" width="66.4541" height="7.24954" rx="3.62477" fill="white" />
                    <rect x="239.423" y="39.1133" width="64.0376" height="7.24954" rx="3.62477" fill="white" />
                    <rect x="316.799" y="39.1133" width="25.3734" height="7.24954" rx="3.62477" fill="white" />
                    <rect x="355.51" y="39.1133" width="61.6211" height="7.24954" rx="3.62477" fill="white" />
                    <rect x="430.469" y="39.1133" width="79.7449" height="7.24954" rx="3.62477" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_699_658">
                        <rect width="499" height="325" rx="15" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_699_658">
                        <rect x="16.9404" y="10.3203" width="14.359" height="14.359" rx="3.95" fill="white" />
                    </clipPath>
                </defs>
            </svg>

            <div className="preview-content">
                <h1 className="title" style={{ color: text }}>{title}</h1>
                <p className="description" dangerouslySetInnerHTML={{ __html: description }} style={{ color: text }} ></p>
                {/* Removing Till we can find a cleaner way to do this, maybe sneak it into the description at the top? otherwise just ignore ig but important functionality so fix soon */}
                {/* {hasPhoto && <image className="line-preview-image" src={lineBanner}></image>} */}
            </div>
        </div>
    )
}

export default LinePreview;

/* global chrome */
import React, { useState, useEffect } from "react";
import "./Redirect.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import { useData } from "../../contexts/DataContext";
import PickettList from "../../components/PickettList/PickettList";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import { postRequest } from "../../hooks/postRequest";
import { useFetch } from "../../hooks/useFetch";

function Redirect() {
  const extensionId = "kakhjmckmkcponjjllcoledhmglccone";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get("data");
  const decodedData = decodeURIComponent(data);
  const parsedData = JSON.parse(decodedData);
  const [picketts, setPicketts] = useState([]);
  const [alternatives, setAlternatives] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const siteData = useFetch(`/api/get-site-info?url=${parsedData.site}`);

    useEffect(()=>{
        if(siteData.data){
            console.log(siteData.data);
            setPicketts(siteData.data.data.map((pickett)=>{
                return {
                    name: pickett.lineID.name,
                    organizer: pickett.lineID.organizer,
                    link: `/line/${pickett.lineID.name}`
                }
            }));
            setAlternatives(siteData.data.data.flatMap((pickett)=>
                pickett.alternatives.map((alternative)=>({
                    name: alternative,
                    organizer: pickett.lineID.organizer,
                    link: `https://${alternative}`
                }))
            ));
            const lastBlockTime = localStorage.getItem(`lastBlock_${siteData.data.data[0].lineID._id}`);
            const thirtyMinutes = 30 * 60 * 1000; // 30 minutes in milliseconds
            
            if (!lastBlockTime || Date.now() - parseInt(lastBlockTime) >= thirtyMinutes) {
                postRequest('/api/log-block', {lineID: siteData.data.data[0].lineID._id});
                localStorage.setItem(`lastBlock_${siteData.data.data[0].lineID._id}`, Date.now().toString());
            }
        }
        if(siteData.error){
            console.log(siteData.error);
        }
    },[siteData.data, siteData.error]);

    useEffect(()=>{
        console.log(picketts);
        console.log(alternatives);
    },[picketts, alternatives]);
    

  useEffect(() => {
    //useEffect for window resizing
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const bypass = () => {
    chrome.runtime.sendMessage(
      extensionId,
      {
        message: "bypass",
        domain: data,
      },
      (response) => {
        if (chrome.runtime.lastError) {
          console.error("Error sending message:", chrome.runtime.lastError);
          return;
        }

        if (response && response.success) {
          console.log("Bypass successful, redirecting...");
          console.log(data);
          const site = JSON.parse(data).site;
          setTimeout(() => {
            window.location.href = `https://${site}`;
          }, 1000);
        } else {
          console.warn("Bypass failed or no response received.");
        }
      }
    );
    // if(siteData.data){
    //     postRequest('/api/just-kidding', {lineID: siteData.data.data[0].lineID._id});
    // }
  };

  const [viewport, setViewport] = useState("100vh");
  useEffect(() => {
    setViewport(window.innerHeight + "px");
  }, []);

  return (
    <div
      className="redirect"
      style={{ minHeight: width < 800 ? viewport : "100vh" }}
    >
      <div className="redirect-content">
        <div className="header">
          <Logo size={18} />
          <p>pickett</p>
        </div>
        <div className="boycott-alert">
          <p>
            looks like you’re trying to access a boycotted website.{" "}
            <span className="highlight">{parsedData.site}</span> is included in
            the following pickett lines:
          </p>
          <div className="secondary-text"></div>
        </div>
        <PickettList picketts={picketts} />
        <p>suggested alternatives</p>
        <PickettList picketts={alternatives} icon="material-symbols:link-rounded"/>
        <div className="bypass" onClick={bypass}>
          <button>Cross the Pickett Line</button>
        </div>
      </div>
      <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
    </div>
  );
}

export default Redirect;

import React, { useState, useEffect } from "react";
import "./TextInput.scss";
import { Icon } from "@iconify/react/dist/iconify.js";

const TextInput = ({ emptyInputText, submitButtonText, onSubmit }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSubmit = () => {
    if (inputValue !== "") {
      onSubmit(inputValue);
      setInputValue("");
    } else {
      console.log("Input is empty");
      // replace with response on user side
    }
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [inputValue]);

  

return (
    <div className="text-input-x-button-wrapper">
        <input
            className={`text-input ${inputValue !== "" ? "has-input" : ""}`}
            placeholder={emptyInputText}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
        />
        <button className={`text-input-button ${inputValue !== "" ? "has-input" : ""}`} onClick={handleSubmit}>
            <span className="text">{submitButtonText}</span>
            <span className="arrow-icon-wrapper">
                <Icon icon="cuida:arrow-right-outline" />
            </span>
        </button>
    </div>
);
};

export default TextInput;
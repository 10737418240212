import React, { useState, useEffect } from "react";
import "./LineSelector.scss";
import { ReactComponent as GroupsIcon } from "../../resources/participantsIcon.svg";
import { useNavigate } from "react-router-dom";
import LinePreviewer from "./linePreview/LinePreviewer";
// import { useData } from "../../contexts/DataContext";
import { useFetch } from '../../hooks/useFetch.js';

// BUG: This component needs heavy reworks for mobile, namely reduce the landing fetch to just 5 items, remove the last click red color and resizing so all 5 items + the join button + the preview can all fit on the page at once

const LineSelector = ({ previewInfo = {}, setPreviewInfo = {}, hasJoinButton = true }) => {
  const [urgentLines, setUrgentLines] = useState([]);
  const [anySelected, setAnySelected] = useState(false);
  const [lastHovered, setLastHovered] = useState(1);
  const [fadeIn, setFadeIn] = useState(true);
  const [fadingOut, setFadingOut] = useState(false);
  const [hovered, setHovered] = useState(1);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const pickettsDisplay = window.innerWidth < 500 ? 4 : 10;

  const navigate = useNavigate();

  // const { data, loading, error } = useFetch("/api/picketts", {
  //   method: "GET",
  //   params: { number: pickettsDisplay },
  // });


  const { data, loading, error } = useFetch('/api/get-popular-lines?number=10');

  useEffect(() => {
    if (loading || error || !data?.data) return;

    const linesWithSelection = data.data.map(line => ({
      id: line._id,
      name: line.name,
      title: line.name,
      coverPic: line.pfp,
      bio: line.bio,
      organizer: line.organizer?.username || 'Unknown',
      organizerPFP: line.organizer?.pfp || '',
      participants: line.followercnt || 0,
      selected: false,
      site: '' // Add other fields as needed
    }));

    setUrgentLines(linesWithSelection);
  }, [data, loading, error]);


  useEffect(() => {
    setAnySelected(urgentLines.some((line) => line.selected));
    setPreviewInfo({ urgentLines: urgentLines.filter((line) => line.selected) });
  }, [urgentLines]);

  useEffect(() => {
    if (fadingOut) {
      const timeout = setTimeout(() => {
        setFadingOut(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [fadingOut]);

  const handleCheckBoxClick = (id) => {
    setUrgentLines((prevLines) =>
      prevLines.map((line) => {
        if (line.id === id) {
          const newParticipants = line.selected
            ? line.participants - 1
            : line.participants + 1;
          return {
            ...line,
            selected: !line.selected,
            participants: newParticipants,
          };
        }
        return line;
      })
    );
  };

  const handleContentBoxClick = (id) => {
    setUrgentLines((prevLines) =>
      prevLines.map((line) => {
        if (line.id === id && line.selected) {
          return {
            ...line,
            selected: false,
            participants: line.participants - 1,
          };
        } else if (line.id === id && !line.selected) {
          navigate(`/line/${line.name}`);
        }
        return line;
      })
    );
  };

  const handleButtonClick = () => {
    const joinedLines = urgentLines
      .filter((line) => line.selected)
      .map((line) => line.name);
    alert(`You have joined the following lines:\n${joinedLines.join("\n")}`);
  };

  const handleContentBoxMouseEnter = (id) => {
    setHovered(id);

    clearTimeout(hoverTimeout);
    if (lastHovered != id) {
      setFadingOut(true);
      setFadeIn(false);
    }
    const timeout = setTimeout(() => {
      setTimeout(() => {
        setLastHovered(id);
        setFadeIn(true);
      }, 100);
    }, 100);

    setHoverTimeout(timeout);
  };

  const handleContentBoxMouseLeave = () => {
    // if (hoverTimeout < 200) {
    //   clearTimeout(hoverTimeout);
    // }
  };

  const lastHoveredLine = urgentLines.find((line) => line.id === lastHovered);

  return (
    <div className="line-viewer-connecter">
      <div className="line-selector-container">
        <div className="line-selector-ui">
          <div className="urgent-lines-container">
            <div className="label-grid">
              <p className="label-first">join</p>
              <p className="label-second">pickett</p>
              <p className="label-third">participants</p>
            </div>
            {/* ERROR: For reasons I can't seem to figure out atm when it goes to grab a line from urgetnLines it tries to read something that isn't there and errors out, I can't tell what the issue is but the fetching itself works... */}
            {urgentLines.map((line) => (
              <div
                key={line.id}
                className={`urgent-line ${line.selected ? "selected" : ""}`}
              >
                <div
                  className={`check-box ${line.selected ? "hide" : ""}`}
                  onClick={() => handleCheckBoxClick(line.id)}
                ></div>
                <div
                  className="content-box"
                  onClick={
                    window.innerWidth < 768
                      ? line.selected
                        ? () => handleContentBoxClick(line.id)
                        : () => handleContentBoxMouseEnter(line.id)
                      : () => handleContentBoxClick(line.id)
                  }
                  onMouseEnter={() => handleContentBoxMouseEnter(line.id)}
                  onMouseLeave={handleContentBoxMouseLeave}
                >
                  <span
                    className={`line-name ${
                      line.id === hovered ? "red-text" : ""
                    }`}
                  >
                    {line.name}
                  </span>
                  <span
                    className={`line-participants line-participants-${
                      line.id
                    } ${line.id === hovered ? "red-text" : ""}`}
                  >
                    <GroupsIcon
                      className={`participants-icon ${
                        line.id === hovered ? "red-icon" : ""
                      }`}
                    />
                    {line.participants.toLocaleString()}
                  </span>
                </div>
              </div>
            ))}
            {hasJoinButton && (
              <div className="join-button-container">
                <div
                  className={`join-selected-button ${
                    anySelected ? "" : "no-selection"
                  }`}
                  onClick={handleButtonClick}
                >
                  Join the Pickett
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {lastHoveredLine && (
        <div className={`last-hovered ${fadeIn ? "visible" : ""}`}>
          <LinePreviewer
            className="home-page-previewer"
            title={lastHoveredLine.title}
            coverPic={lastHoveredLine.coverPic}
            bio={lastHoveredLine.bio}
            organizer={lastHoveredLine.organizer}
            organizerPFP={lastHoveredLine.organizerPFP}
            participants={lastHoveredLine.participants}
            site={lastHoveredLine.site}
            onChange={() => handleContentBoxMouseEnter(lastHoveredLine.id)}
          />
        </div>
      )}
    </div>
  );
};

export default LineSelector;
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./FileUpload.scss";
import { Icon } from "@iconify/react/dist/iconify.js";
import AnimatedCheckIcon from "../AnimatedCheckToX/AnimatedCheckToX";

const FileUpload = ({ classroomName, onFileChange, currentFile }) => {
  const [selectedFile, setSelectedFile] = useState(currentFile || null);
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState(currentFile ? currentFile.name : "");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (currentFile !== selectedFile) {
      setSelectedFile(currentFile);
      setFileName(currentFile ? currentFile.name : "");
    }
  }, [currentFile]);

  const onFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file ? file.name : "");
    onFileChange(file);
  };

  const onFileUpload = async () => {
    if (!selectedFile) {
      setMessage("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      const response = await axios.post(
        `/upload-image/${classroomName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setMessage("File uploaded successfully");
      // You might want to do something with the response here
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("Error uploading file");
      onFileChange(null);
    }
  };

  const onFileRemove = () => {
    setSelectedFile(null);
    setFileName("");
    onFileChange(null);
  };

  return (
    <div className="file-upload-container">
      <label
        className={`file-upload ${selectedFile ? "complete" : ""}`}
        htmlFor="fileInput"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="icon-container">
          {selectedFile ? (
            <AnimatedCheckIcon 
              className="check-icon"
              size="18px"
              isHovered={isHovered}
              defaultColor="var(--background-color)"
              hoverColor="var(--background-color)"
            />
          ) : (
            <Icon className="upload-icon" icon="line-md:uploading-loop" />
          )}
        </div>
        <div className="text-container">
          <h3>{selectedFile ? fileName : "Click to upload"}</h3>
          <p>{message ? message : "Maximum size: 5MB"}</p>
        </div>
        <input
          type="file"
          id="fileInput"
          onChange={onFileSelect}
          style={{ display: "none" }}
        />
      </label>
    </div>
  );
};

export default FileUpload;
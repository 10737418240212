import React, { useState, useEffect } from "react";
import "./Profile.scss";
import { useData } from "../../contexts/DataContext";
import { useParams } from "react-router-dom";

import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import LineViewer from "../../components/LineViewer/LineViewer.jsx";
import ProfilePreview from "../../components/ProfilePreview/ProfilePreview.jsx";
import PinnedLinePreviewer from "../../components/PinnedLinePreviewer/PinnedLinePreviewer.jsx";
import UserStatDisplay from "../../components/UserStatDisplay/UserStatDisplay.jsx";

import testPFP from "../../resources/johnK.jpg";

function Profile() {
    const name = useParams().name;
  const { fetchData } = useData();
  const [picketts, setPicketts] = useState([]);
  const [pinnedLine, setPinnedLine] = useState(null);
  const [user, setUser] = useState(null);  


  useEffect(() => {
    fetchData("/api/picketts").then((response) => {
      if (response.success && response.data.length > 0) {
        setPicketts(response.data);
        setPinnedLine(response.data[0]);
      }
    });
    fetchData("/api/user", "get", null, {params: { username:name }}).then((response) => {
        console.log(response);
      if(response.success) {
        setUser(response.data);
      }
    });
  }, [fetchData]);
  
  if(!user) {
    return <div></div>
  }

  return (
    <div className="Profile page">
      {/* need to make navbar component version for logged in vs not  */}
      {/* <NavBar /> */}
       <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
      <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "10px"}}>
        {/*add page content here  */}
        {/* 3/4 */}
        <span className="temp-vert-aligner">
          <span className="temp-horz-aligner-top">
            <div style={{width: "40%"}}>
              {/* need to make this cut off properly */}
              {/* also need to make sure the actual image is being imported */}
            <ProfilePreview
              imageSrc={testPFP}
              name={user.username}
              description={
                user.bio
              }
            />
            </div>
            
            <div style={{width: "60%"}}>
              {user && (
            <UserStatDisplay
              followers={user.stats.followers}
              following={user.stats.following}
              lines={user.stats.lines}
              donations={`$${user.stats.donated.toFixed(2)}`}
              streak={user.streak}
              badges={"Coming Soon!"}
            />
              )}
            </div>
          </span>
          <span className="temp-horz-aligner-bottom">
          <div style={{width: "40%"}}>
          {pinnedLine && (
            <PinnedLinePreviewer
              title={pinnedLine.title}
              coverPic={pinnedLine.coverPic}
              bio={pinnedLine.bio}
              organizer={pinnedLine.organizer}
              organizerPFP={pinnedLine.organizerPFP}
              participants={pinnedLine.participants}
              site={pinnedLine.site}
            />
          )}
            </div>
            <div style={{width: "60%"}}>
            <LineViewer lines={picketts}/>
            </div>
          </span>

        </span>
      </div>
    </div>
  );
}

export default Profile;

import React, { useState, useRef, useEffect } from 'react';
import './PickettList.scss';
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from 'react-router-dom';

function PickettList({ picketts, show = 3, icon="material-symbols:arrow-outward" }) {
  const [seeMore, setSeeMore] = useState(false);
  const [listHeight, setListHeight] = useState(0);
  const [shownCount, setShownCount] = useState(
    picketts.length > show ? show : picketts.length
  );

  console.log(picketts);

  const listItemRef = useRef(null);

  const calculateListHeight = () => {
    if (listItemRef.current && listItemRef.current.children.length > 0) {
      const itemHeight = listItemRef.current.children[0].clientHeight;
      const totalHeight = itemHeight * shownCount + 12 * (shownCount - 1);
      setListHeight(totalHeight);
    } else {
      setTimeout(() => {
        calculateListHeight();
      }, 100);
    }
  };

  useEffect(() => {
    calculateListHeight();
  }, [picketts, shownCount]);

  useEffect(() => {
    setShownCount(seeMore ? picketts.length : Math.min(show, picketts.length));
  }, [seeMore, picketts.length, show]);

  const showToggleButton = picketts.length > show;

  return (
    <div className="pickett-list">
      <div
        className="list-items"
        style={{ height: listHeight === 0 ? 'auto' : `${listHeight}px` }}
        ref={listItemRef}
      >
        {picketts.slice(0, shownCount).map((pickett) => (
            <Link to={pickett.link} key={pickett.id} className='pickett'>
                <div className="left">
                    <Icon icon={icon}/>
                    <p>{pickett.name}</p>
                </div>
                <div className="right">
                    <p>{pickett?.organizer?.username}</p>
                </div>
          </Link>
        ))}
        <div
          className={`gradient ${
            showToggleButton && !seeMore ? '' : 'invisible'
          }`}
        ></div>
      </div>

      {showToggleButton && (
        <button onClick={() => setSeeMore(!seeMore)}>
          {seeMore ? 'see less' : 'see all'}
        </button>
      )}
    </div>
  );
}

export default PickettList;

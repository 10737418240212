import React from "react";
import "./UserStatDisplay.scss";
import Label from "./../Label/Label";
import "../../css/colors.css";

function UserStatDisplay({
  followers,
  following,
  lines,
  donations,
  streak,
  badges,
}) {
  const getStreakColor = (streak) => {
    const streakColors = {
      365: "var(--accent-ice-blue)",
      183: "var(--accent-color)",
      31: "var(--accent-orange)",
      7: "var(--text-color)",
    };

    for (const [key, value] of Object.entries(streakColors).reverse()) {
      if (streak >= key) return value;
    }

    return "var(--light-bounding-color)";
  };

  return (
    <div className="profile-stat-box">
      <Label label="stats" className="stats-label">
        <div className="stat-view">
          <div className="stat-grid">
            <StatItem value={followers} label="followers" />
            <StatItem value={following} label="following" />
            <StatItem value={lines} label="lines" />
            <StatItem value={donations} label="donations" />
          </div>
        </div>
      </Label>

      <Label label="" className="streak-label">
        <div className="streak-view">
          <span className="streak-value-col">
            <h1
              className="streak-number"
              style={{ color: getStreakColor(streak) }}
            >
              {streak}
            </h1>
            <h4
              className="streak-sub-heading"
              style={{ color: getStreakColor(streak) }}
            >
              streak
            </h4>
          </span>
        </div>
      </Label>

        <Label label="badges" className="badges-label">
        <div className="badge-view">Coming Soon!</div>
        </Label>
      {/* </div> */}
    </div>
  );
}

function StatItem({ value, label }) {
  return (
    <div className="stat-item">
      <h1 className="profile-stat-main">{value}</h1>
      <h4 className="profile-stat-sub-header">{label}</h4>
    </div>
  );
}

export default UserStatDisplay;

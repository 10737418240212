import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import "./NavBar.scss";
import CustomSwitch from "../Switch/Switch";
import MobileSwitch from "../Switch/MobileSwitch/MobileSwitch";
import Logo from "../Logo/Logo";
import { Icon } from "@iconify/react";


// import pfp from "../../resources/johnK.jpg";

const NavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dropdownRef = useRef(null);

    const options = ["home", "create", "search", "about", "donate"];
    const pathName = location.pathname.split("/")[1];
    let index = 0;
    switch (pathName) {
        case "":
            index = 0;
            break;
        case "create-line":
            index = 1;
            break;
        case "search":
            index = 2;
            break;
        case "about":
            index = 3;
            break;
        case "donate":
            index = 4;
            break;
        default:
            index = 0;
            break;
    }
    
    const { user, isAuthenticating, logout } = useAuth();
    
    const [toggleMobile, setToggleMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        
        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    const handleTabChange = (index) => {
        console.log("Changed Tab");
        setToggleMobile(false);
        if(index === 0){
            navigate('/');
        } 
        else if (index === 1){
            // you NEED an inbetween page here, the transiation is way to harsh for users with no easy way to go back this will lead to churn, same with the donate link tbh
            navigate('/create-line');
        } else if(index === 2) {
            navigate('/search')
        } else if(index === 3){
            navigate('/about');
        } else if(index === 4){
            window.open('https://ko-fi.com/pickett', '_blank');
            // navigate('/line/BDS Official Boycott');
        }
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleProfileClick = () => {
        setDropdownOpen(false);
        if (user) {
            navigate(`/profile/${user.username}`);
        } else {
            navigate(`/profile/johnklaumf`);
        }
    };

    const handleSettingsClick = () => {
        setDropdownOpen(false);
        navigate('/account-settings');
    };

    const handleLogoutClick = () => {
        setDropdownOpen(false);
        logout();
    };

    // Loading state while checking authentication
    if (isAuthenticating) {
        return (
            <div className="nav-bar">
                <div className="nav-left">
                    {width < 500 ?
                        <div onClick={()=>{setToggleMobile(!toggleMobile)}}>
                            <Logo size={25} />
                        </div>
                        : 
                        <a onClick={()=>{navigate('/')}}><Logo size={25} /></a>
                    }
                    <div className="break" />
                    <div className="nav-bar-container">
                        {width < 500 ? 
                        <MobileSwitch
                            options={options}
                            onChange={handleTabChange}
                            toggle={toggleMobile}
                            setToggle={setToggleMobile}
                            initialSelected={index}
                        />
                        :
                        <CustomSwitch
                            options={options}
                            onChange={handleTabChange}
                            toggle={toggleMobile}
                            setToggle={setToggleMobile}
                            initialSelected={index}
                        />
                        }
                    </div>
                </div>
                <div className="nav-right">
                    {/* Empty placeholder while loading */}
                </div>
            </div>
        );
    }

    return (
        <div className="nav-bar">
            <div className="nav-left">
                {width < 500 ?
                    <div onClick={()=>{setToggleMobile(!toggleMobile)}}>
                        <Logo size={25} />
                    </div>
                    : 
                    <a onClick={()=>{navigate('/')}}><Logo size={25} /></a>
                }
                <div className="break" />
                <div className="nav-bar-container">
                    {width < 500 ? 
                    <MobileSwitch
                        options={options}
                        onChange={handleTabChange}
                        toggle={toggleMobile}
                        setToggle={setToggleMobile}
                        initialSelected={index}
                    />
                    :
                    <CustomSwitch
                        options={options}
                        onChange={handleTabChange}
                        toggle={toggleMobile}
                        setToggle={setToggleMobile}
                        initialSelected={index}
                    />
                    }
                </div>
            </div>
            <div className="nav-right">
                {user ? (
                    // User is authenticated - show dropdown
                    <div className="user-dropdown" ref={dropdownRef}>
                        <div className="user-dropdown-header" onClick={toggleDropdown}>
                            <div className="pfp">
                                <img src={user.pfp} alt="" />
                            </div>
                            <p>{user.username}</p>
                        </div>
                        {dropdownOpen && (
                            <div className="dropdown-menu">
                                <div className="dropdown-item" onClick={handleProfileClick}>
                                    <Icon icon="mdi:account" className="dropdown-icon" />
                                    <span>Profile</span>
                                </div>
                                <div className="dropdown-item" onClick={handleSettingsClick}>
                                    <Icon icon="mdi:cog" className="dropdown-icon" />
                                    <span>Settings</span>
                                </div>
                                <div className="dropdown-item" onClick={handleLogoutClick}>
                                    <Icon icon="mdi:logout" className="dropdown-icon" />
                                    <span>Logout</span>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    // User is not authenticated - show login/join buttons
                    <>
                        <div className="login-button" onClick={()=>{navigate("/login")}}> login </div>
                        <div className="join-button" onClick={()=>{navigate("/login")}}> join</div>
                    </>
                )}
            </div>
        </div>
    );
};

export default NavBar;
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { sendTokenToExtension, checkExtensionInstalled } from '../services/extensionService';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(true); // [1
    const [user, setUser] = useState(null);

    const extensionId = "kakhjmckmkcponjjllcoledhmglccone";
    /* global chrome */

    // const checkExtensionInstalled = (extensionId) => {
    //     return new Promise((resolve) => {
    //       try {
    //         chrome.runtime.sendMessage(
    //           extensionId,
    //           { message: "ping" },
    //           (response) => {
    //             if (chrome.runtime.lastError) {
    //               console.error("Error:", chrome.runtime.lastError.message);
    //               resolve(false); // Extension not installed
    //             } else if (response && response.success) {
    //               resolve(true); // Extension installed
    //             } else {
    //               resolve(false); // Unexpected response
    //             }
    //           }
    //         );
    //       } catch (error) {
    //         console.error("Exception:", error);
    //         resolve(false);
    //       }
    //     });
    //   };
    
    // const sendTokenToExtension = (extensionId, token) => {
    //     try {
    //       chrome.runtime.sendMessage(
    //         extensionId,
    //         { message: "token", token },
    //         (response) => {
    //           if (chrome.runtime.lastError) {
    //             console.error("Error:", chrome.runtime.lastError.message);
    //           } else if (response && response.success) {
    //             console.log("Token sent to extension");
    //           } else {
    //             console.error("Unexpected response:", response);
    //           }
    //         }
    //       );
    //     } catch (error) {
    //       console.error("Exception:", error);
    //     }
    //   }
    
    // useEffect(() => {
    //     const checkExtension = async () => {
    //         const isInstalled = await checkExtensionInstalled(extensionId);
    //         console.log('Extension installed:', isInstalled);
    //     };
    //     checkExtension();
    // }, []);

    const validateToken = async () => {
        const token = localStorage.getItem('token');
        try {
            // Set up the Authorization header
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };
            // Make the GET request to the validate-token endpoint
            const response = await axios.get('/api/validate-token', config);

            console.log('Token validation response:', response.data);
            // Handle response...
            if (response.data.success) {
                setUser(response.data.data.user);
                console.log(response.data.data.user);
                setIsAuthenticated(true);
                setIsAuthenticating(false);
            } else {
                setIsAuthenticated(false);
                setIsAuthenticating(false);
                localStorage.removeItem('token');
            }
        } catch (error) {
            localStorage.removeItem('token');
            console.log('Token expired or invalid');
            setIsAuthenticated(false);
            setIsAuthenticating(false);
            return error;
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            validateToken();
        } else {
            setIsAuthenticated(false);
            setIsAuthenticating(false);
        }
    }, []);

    const login = async (credentials) => {
        try {
            const response = await axios.post('/api/login', credentials);
            if (response.status === 200) {
                localStorage.setItem('token', response.data.data.token);
                //redirect to https://your-extension-id.chromiumapp.org/#token=JWT_TOKEN_HERE
                if(checkExtensionInstalled(extensionId)){
                    sendTokenToExtension(extensionId, response.data.data.token);
                }
                setIsAuthenticated(true);
                setUser(response.data.data.user);
                console.log(response.data);
                // addNotification({ title:'Logged in successfully',type: 'success'});
                
            }
        } catch (error) {
            // console.error('Login failed:', error);
            // Handle login error
            throw error;
        }
    };

    const googleLogin = async (code, isRegister) => {
        try {
            const url = window.location.href;
            const response = await axios.post('/api/google-login', { code, isRegister, url });
            // Handle response from the backend (e.g., storing the token, redirecting the user)
            console.log('Backend response:', response.data);
            localStorage.setItem('token', response.data.data.token);
            if(checkExtensionInstalled(extensionId)){
                sendTokenToExtension(extensionId, response.data.data.token);
            }
            setIsAuthenticated(true);
            setUser(response.data.data.user);
            // addNotification({title: 'Logged in successfully',type: 'success'});
            // For example, redirect the user or store the received token in local storage
        } catch (error) {
            console.error('Error sending code to backend:', error);
            // Handle error
            throw error;
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUser(null);
        // addNotification({title: 'Logged out successfully',success: 'success'});
    };

    const values = {
        isAuthenticated,
        user,
        login,
        logout,
        googleLogin,
        validateToken,
        isAuthenticating
    }

    return (
        <AuthContext.Provider value={values}>
            {children}
        </AuthContext.Provider>
    );
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './ProfilePip.scss';

const ProfilePip = ({ userId }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            if (!userId) {
                setError('User ID is required');
                setLoading(false);
                return;
            }

            try {
                const response = await fetch('/search-user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ _id: userId }),
                });

                if (!response.ok) throw new Error('Network response was not ok');
                const userData = await response.json();
                setUser(userData);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    if (loading) return <div className="loading-spinner">Loading...</div>;
    if (error) return <div className="profile-error">Error: {error}</div>;
    if (!user || !user.username) return <div className="profile-error">User data is incomplete</div>;

    return (
        <Link 
            to={`/profile/${user.username}`}
            className="profile-link"
        >
            <img 
                src={user.profilePicture || "https://via.placeholder.com/150"} 
                alt={`${user.username}'s profile picture`} 
                className="profile-image" 
            />
            <h3 className="account-name">{user.username}</h3>
        </Link>
    );
};

ProfilePip.propTypes = {
    userId: PropTypes.string.isRequired
};

export default ProfilePip;